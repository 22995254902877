var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-students-list-page"},[(_vm.pendingStudentApprovalsCount > 0)?_c('v-card',{staticClass:"mb-2",attrs:{"color":"primary lighten-5"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap body-2"},[_vm._v(" There are "+_vm._s(_vm.pendingStudentApprovalsCount)+" pending approvals for registered students ")])],1),_c('v-list-item-icon',[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":_vm.openPendingStudentApprovalsList}},[_vm._v(" View ")])],1)],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('intract-smart-list',{ref:"admin-students-smart-list",staticClass:"mt-4",attrs:{"paginated":"","endpoint":_vm.studentEndpoint,"handle-click":_vm.handleClick,"filter-fields":_vm.filterFields,"item-options":{
          avatar: 'image',
          handleClick: _vm.handleClick,
          appendIcon: 'mdi-arrow-right',
        }},scopedSlots:_vm._u([{key:"list-item-content",fn:function({ item: student }){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(student.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.room.sections))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.username))])],1)]}}])})],1)],1),_c('intract-create-edit-sheet',{key:"student-create-sheet",staticClass:"student-create-sheet",attrs:{"persistent":"","title":"Create Student","visible":_vm.createStudentSheet.visible,"fields":_vm.studentCreationForm,"submit-button-text":"Create","endpoint":_vm.endpoints.studentViewSet,"edit-id":_vm.createStudentSheet.editId,"data-object":_vm.createStudentSheet.obj,"create-success-message":_vm.createStudentSheet.createSuccessMessage},on:{"close":function($event){_vm.createStudentSheet.visible = false;
      _vm.createStudentSheet.editId = null;
      _vm.createStudentSheet.obj.image = null;},"objectCreated":_vm.studentCreated,"updateObject":(obj) => (_vm.createStudentSheet.obj = obj)},scopedSlots:_vm._u([{key:"custom-field--image",fn:function({ obj }){return [_c('v-col',{staticClass:"text-center"},[_c('div',[_c('v-avatar',{staticClass:"mb-2",attrs:{"size":"100px"}},[(!obj.image)?_c('v-img',{attrs:{"src":require("../../../assets/images/default.jpg")}}):_c('v-img',{attrs:{"src":obj.image.document}})],1)],1),_c('v-btn',{staticClass:"my-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$refs.profileImageField.$refs.input.click()}}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-image")]),(!obj.image)?_c('span',[_vm._v("Add Image")]):_c('span',[_vm._v("Edit Image")])],1),_c('v-file-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"profileImageField",staticClass:"ma-0 pa-0",attrs:{"accept":"image/*"},on:{"change":(file) => _vm.addProfileImage(file, obj)}})],1)]}},{key:"custom-field--username",fn:function({ obj }){return [_c('v-text-field',{attrs:{"outlined":"","error-messages":_vm.usernameError,"maxlength":"30","hide-details":"auto","rules":_vm.usernameRules},on:{"input":function($event){return _vm.checkUsernameExists(obj.username)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Username"),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]},proxy:true}],null,true),model:{value:(obj.username),callback:function ($$v) {_vm.$set(obj, "username", $$v)},expression:"obj.username"}}),(obj.phone || obj.full_name)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Suggestions: "),(obj.phone && obj.username != obj.phone)?_c('v-chip',{staticClass:"mx-1",on:{"click":function($event){obj.username = obj.phone;
            _vm.checkUsernameExists(obj.username);}}},[_vm._v(_vm._s(obj.phone))]):_vm._e(),(
            obj.full_name && obj.username != _vm.convertToUsername(obj.full_name)
          )?_c('v-chip',{staticClass:"mx-1",on:{"click":function($event){obj.username = _vm.convertToUsername(obj.full_name);
            _vm.checkUsernameExists(obj.username);}}},[_vm._v(_vm._s(_vm.convertToUsername(obj.full_name)))]):_vm._e()],1):_vm._e()]}}])}),_c('intract-list-sheet',{attrs:{"visible":_vm.showDisabledStudents,"title":"Deactivated Students"},on:{"close":function($event){_vm.showDisabledStudents = false}},scopedSlots:_vm._u([{key:"list",fn:function(){return [_c('intract-smart-list',{ref:"disabled-students-smart-list",attrs:{"paginated":"","endpoint":_vm.disabledStudentsEndpoint,"item-options":{
          avatar: 'image',
          handleClick: _vm.handleClick,
          appendIcon: 'mdi-arrow-right',
        }},scopedSlots:_vm._u([{key:"list-item-content",fn:function({ item: student }){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(student.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.room.sections))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.username))])],1)]}}])})]},proxy:true}])}),_c('intract-list-sheet',{attrs:{"visible":_vm.showPendingStudentApprovals,"title":"Pending Approvals"},on:{"close":function($event){_vm.showPendingStudentApprovals = false}},scopedSlots:_vm._u([{key:"list",fn:function(){return [_c('intract-smart-list',{ref:"pending-student-approvals-smart-list",attrs:{"paginated":"","endpoint":_vm.pendingStudentApprovalsEndpoint,"item-options":{
          avatar: 'image',
          handleClick: _vm.handleClick,
          appendIcon: 'mdi-arrow-right',
        }},on:{"updateTotalCount":(count) => _vm.pendingStudentApprovalsCount = count},scopedSlots:_vm._u([{key:"list-item-content",fn:function({ item: student }){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(student.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.room.sections))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.username))])],1)]}},{key:"list-item-right",fn:function({ item: student }){return [_c('v-list-item-icon',{staticClass:"align-self-top"},[_c('small',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.moment(student.created).fromNow()))])])]}}])})]},proxy:true}])}),_c('v-fab-transition',[(!_vm.isLoading && !_vm.currentUser.is_student)?_c('v-btn',{attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":_vm.addStudent}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('image-cropper-dialog',{ref:"cropperDialog",attrs:{"uploadedImage":_vm.uploadedImage},on:{"onCrop":(croppedImage) => {
      _vm.updateObj.image = {
        document: croppedImage,
        file_name: _vm.imageName,
      };
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }